$(function () {

    var tabsTitleLength = $('.tab-title').length;
    var productCategoryButtonLength = $('.product-category-button').length;

    tabsLengthClass = tabsTitleLength + productCategoryButtonLength;


    $('.tabs-wrapper').addClass('has-' + tabsLengthClass + '-tabs');

    $('.tab-title').on('click', function() {
        var tabContent = $('#' + $(this).attr('data-tab-target'));

        if($(this).hasClass('active')){
            $(this).removeClass('active');
            tabContent.removeClass('active');
            
        } else {
            $('.tab-title').removeClass('active');
            $('.tab-content').removeClass('active');

            $(this).addClass('active');
            tabContent.addClass('active');
        }
    });
});