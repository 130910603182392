$(function () {
    var observerConfig = {
        root: null,
        rootMargin: '0px',
        threshold: 0.6
    }

    function observerFn(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active')
                observer.unobserve(entry.target)
            }
        })
    }

    var observer = new IntersectionObserver(observerFn, observerConfig)

    var banners = document.querySelectorAll('.homepage-banners')
    var usps = document.querySelectorAll('.usps-container')

    banners.forEach( banner => {
        observer.observe(banner)
    })

    usps.forEach( usp => {
        observer.observe(usp)
    })


    //CUSTOM CURSOR VIEW ON IMAGES
    $('.show-custom-cursor').mouseenter(function(e) {
        $('.custom-cursor__view').addClass('cursor__view--anim');
    }).mouseleave(function(e) {
        $('.custom-cursor__view').removeClass('cursor__view--anim');
    });

    $('.show-custom-cursor').on('mousemove', function(e) {
        $('.custom-cursor').css({
            "transform": "translate3d(" + (e.clientX) + "px, " + (e.clientY) + "px, 0px)"
        });
    });


    //WISHLIST ICON FILLED
    $('.add-to-wishlist-button').on('click', function() {
        $(this).addClass('active')
    })

    $('.products-wrapper').on('click', '.add-to-wishlist-button', function() {
        $(this).addClass('active')
    })
})