document.addEventListener('DOMContentLoaded', () => {
    let customSelects

    customSelects = document.querySelectorAll('.custom-select')

    customSelects.forEach(customSelect => {
        createSelect(customSelect)
    })

    /*if the user clicks anywhere outside the select box, then close all select boxes:*/
    document.addEventListener("click", closeAllSelect)
})

const createSelect = (customSelect) => {
    let selectElement, selectOptions, newSelect, newSelectOptions, newSelectOption

    selectElement = customSelect.querySelector('select') //default select
    newSelect = customSelect.querySelector('.select-selected') //div that will act as the selected item
    newSelect.classList = newSelect.classList + ' ' + selectElement.classList

    newSelect.innerHTML = selectElement.options[selectElement.selectedIndex].innerHTML;

    /*for each element, create a new DIV that will contain the option list:*/
    newSelectOptions = document.createElement('DIV')
    newSelectOptions.setAttribute('class', 'select-items select-hide')

    selectOptions = selectElement.querySelectorAll('option');

    //for each option in the original select element, create a new DIV that will act as an option item
    selectOptions.forEach((selectOption) => {
        newSelectOption = document.createElement("DIV");
        newSelectOption.innerHTML = selectOption.innerHTML;
        newSelectOption.classList = selectOption.classList + 'custom-option';

        //if the element is the same as the selected option, then add class same-as-selected
        if (newSelectOption.innerHTML == newSelect.innerHTML) {
            newSelectOption.classList.add('same-as-selected');
        }

        //when an item is clicked, update the original select box, and the selected item
        newSelectOption.addEventListener("click", function (e) {
            const parentSelect = this.parentNode.parentNode.querySelector('select')
            const newSelectSibling = this.parentNode.previousElementSibling

            for (let i = 0; i < parentSelect.length; i++) {
                if (parentSelect.options[i].innerHTML == this.innerHTML) {
                    parentSelect.selectedIndex = i;
                    newSelectSibling.innerHTML = this.innerHTML;

                    this.parentNode.querySelector('.same-as-selected').classList.remove('same-as-selected')

                    this.classList.add("same-as-selected")

                    break
                }
            }

            newSelectSibling.click();
            parentSelect.dispatchEvent(new Event('change')); //trigger change event to select element
        })

        newSelectOptions.appendChild(newSelectOption);
    })

    customSelect.appendChild(newSelectOptions)

    newSelect.addEventListener("click", function (e) {
        //when the select box is clicked, close any other select boxes, and open/close the current select box:
        e.stopPropagation()
        closeAllSelect(this);
        this.nextElementSibling.classList.toggle("select-hide")
        this.classList.toggle("custom-select-active")
    })
}
  
const closeAllSelect = (element) => {
    //a function that will close all select boxes in the document, except the current select box:
    let selectItems, selectSelected, array = []

    selectItems = document.querySelectorAll('.select-items')
    selectSelected = document.querySelectorAll('.select-selected')

    selectSelected.forEach((select, index) => {
        if(element === select) {
            array.push(index)
        } else {
            select.classList.remove('custom-select-active')
        }
    })

    selectItems.forEach((item, index) => {
        if (array.indexOf(index)) {
            item.classList.add("select-hide")
        }
    })
}

// export { createSelect }